import React, {FC} from 'react';
import Button from '../button';
import { openCalendly } from '../../helpers/trackingEvents';
import requestDemoCta from '../../images/demo-request-cta.png';
import './styles.scss';

const RequestDemoCta: FC = () => {
  return (
    <div className="request-demo-cta-container m-auto">
      <div>
        <h2 className="title">Still have questions?</h2>
        <img src={requestDemoCta} className="d-lg-none my-2 mt-3 section-image"></img>
        <p className="text text-center text-md-left">
          Can’t find the answer you’re looking for? <br />
          Please request a demo to our friendly team.
        </p>
        <div className="d-flex d-md-block">
          <Button className="m-auto" onClick={openCalendly}>
            Request a demo
          </Button>
        </div>
      </div>
      <div>
        <img src={requestDemoCta} className="section-image ml-0 d-none d-lg-flex" width={409} height={163} style={{maxWidth: '409px', height: 'auto', width: '100%'}} />
      </div>
    </div>
  );
};

export default RequestDemoCta;
