import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import Layout from '../../components/layout/layout';
import Section from '../../components/section';
import RequestDemoCta from '../../components/request-demo-cta';
import vimeo from '../../images/hexowatchScreens/vimeo.png';
import tools from '../../images/why-we-build-hexowatch/tools.png';
import arrow from '../../images/why-we-build-hexowatch/arrow.png';
import step1 from '../../images/why-we-build-hexowatch/step-1.png';
import step1Img from '../../images/why-we-build-hexowatch/step-1-img.png';
import step2 from '../../images/why-we-build-hexowatch/step-2.png';
import step2Img from '../../images/why-we-build-hexowatch/step-2-img.png';
import step3 from '../../images/why-we-build-hexowatch/step-3.png';
import step3Img from '../../images/why-we-build-hexowatch/step-3-img.png';
import illustration1 from '../../images/integrations/illustration-1.png';
import illustration2 from '../../images/integrations/illustration-2.png';
import './styles.scss';

const About = () => {
  const [iframe, setIframe] = useState(false);

  return (
    <Layout noBg>
      <section id="seo_home_area" className="seo_home_area with-bg">
        <div className="container_home">
          <div className="row_home pb-3">
            <Row className="m-auto justify-content-center w-100">
              <Col lg={6} className="my-auto">
                <h1 className="f_size_38 f_700 l_height50 w_color text-center text-lg-left">Why We Built Hexowatch</h1>
                <div className="w_color f_size_16 text-center text-lg-left m-auto m-lg-0" style={{maxWidth: '400px'}}>
                  With 13 different page change monitoring types, Hexowatch makes it easy to track everything that's
                  important to you on the internet.
                </div>
                <div className="d-flex d-lg-none w-100 my-4">
                  {iframe ? (
                    <iframe
                      src="https://player.vimeo.com/video/787835847?h=82699d8d62&autoplay=1"
                      width="360"
                      height="200"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      style={{maxWidth: '100%', margin: 'auto', height: 'auto', minHeight: '200px', borderRadius: '10px'}}
                    ></iframe>
                  ) : (
                    <img
                      style={{maxWidth: '100%', margin: 'auto', cursor: 'pointer', borderRadius: '10px'}}
                      src={vimeo}
                      alt="vimeo"
                      onClick={() => setIframe(true)}
                    />
                  )}
                </div>
              </Col>
              <Col lg={6} className="d-none d-lg-flex mb-4">
                {iframe ? (
                  <iframe
                    src="https://player.vimeo.com/video/787835847?h=82699d8d62&autoplay=1"
                    width="558"
                    height="310"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    style={{maxWidth: '100%', margin: 'auto', borderRadius: '10px'}}
                  ></iframe>
                ) : (
                  <img
                    style={{margin: 'auto', cursor: 'pointer', width: '560px', height: 'auto', borderRadius: '10px', maxWidth: '100%'}}
                    src={vimeo}
                    alt="vimeo"
                    onClick={() => setIframe(true)}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <Section className="py-5">
        <Col md={6} className="my-auto">
          <h2 className="f_size_36 f_700 l_height50 t_color2 text-center">
            We monitor websites for <br className="d-none d-md-flex" />
            changes, <span className="blue_color">so you don’t have to.</span>
          </h2>
        </Col>
        <Col md={6}>
          <img src={tools} style={{maxWidth: '100%'}} alt="13 different page change monitoring types" />
        </Col>
      </Section>
      <Section lightGrey className="py-5">
        <div className="col-12 mb-4">
          <h2 className="f_size_36 f_700 l_height50 t_color2 text-center">
            Capture changes on autopilot –{' '}
            <span className="blue_color">
              in just
              <br className="d-none d-md-flex" /> three steps.
            </span>
          </h2>
        </div>

        <Col lg={4} className="text-center position-relative">
          <img src={step1} alt="" />
          <img
            src={arrow}
            alt=""
            className="d-none d-lg-flex"
            style={{position: 'absolute', top: '0px', left: '300px'}}
          />
          <div>
            <h3 className="f_size_18 f_700 l_height30 t_color2 text-center mt-4">
              Select the type of change to detect
            </h3>
            <p className="f_size_14 f_400 t_color4 text-center">
              Choose from one of our 13 monitoring types to detect visual, content, keyword, source code, technology,
              availability, API, or price changes to name a few.
            </p>
          </div>
          <img src={step1Img} alt="Select change monitoring tools" />
        </Col>
        <Col lg={4} className="text-center" style={{marginTop: '50px'}}>
          <img src={step2} />
          <h3 className="f_size_18 f_700 l_height30 t_color2 text-center mt-4">Enter the URL of the page to monitor</h3>
          <p className="f_size_14 f_400 t_color4 text-center">
            Depending on your objectives, you can go broad or specify specific conditions that have to be met for a
            change to be detected.
          </p>
          <img src={step2Img} alt="Enter the URL to monitor" />
        </Col>
        <Col lg={4} className="text-center mt-lg-100">
          <img src={step3} />
          <h3 className="f_size_18 f_700 l_height30 t_color2 text-center mt-4">Get alerts when changes are detected</h3>
          <p className="f_size_14 f_400 t_color4 text-center">
            When changes are detected, we archive a snapshot of the changes so you can easily compare them and stay
            informed.
          </p>
          <img src={step3Img} alt="Stay informed about the changes" />
        </Col>
      </Section>
      <Section className="blue py-5 integrations px-3">
        <img src={illustration1} className="illustration1" alt="" />
        <img src={illustration2} className="illustration2" alt="" />
        <RequestDemoCta />
      </Section>
    </Layout>
  );
};

export default About;
